import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { Loader2, Mail, Lock } from 'lucide-react';
import './Login.css';

const ENCRYPTION = process.env.REACT_APP_ENCRYPTION;

const Login = ({ onLogin }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [stayLoggedIn, setStayLoggedIn] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [resetEmail, setResetEmail] = useState('');

    useEffect(() => {
        const checkAuth = () => {
            const encryptedAuth = Cookies.get('authenticated');
            if (encryptedAuth) {
                // Decrypt the authentication status
                const bytes = CryptoJS.AES.decrypt(encryptedAuth, ENCRYPTION);
                const isAuthenticated = bytes.toString(CryptoJS.enc.Utf8);
                if (isAuthenticated === 'true') {
                    setIsLoggedIn(true);
                }
            }
        };

        checkAuth();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            await signInWithEmailAndPassword(auth, formData.email, formData.password);
            // Encrypt the authentication status
            const encryptedAuth = CryptoJS.AES.encrypt('true', ENCRYPTION).toString();
            Cookies.set('authenticated', encryptedAuth, { expires: stayLoggedIn ? 30 : 1, path: '/' });

            if (typeof onLogin === 'function') {
                onLogin();
            }

            navigate('/status');
        } catch (error) {
            let errorMessage = 'Ocorreu um erro ao fazer login.';

            switch (error.code) {
                case 'auth/invalid-email':
                    errorMessage = 'Email inválido.';
                    break;
                case 'auth/user-disabled':
                    errorMessage = 'Esta conta foi desativada.';
                    break;
                case 'auth/user-not-found':
                    errorMessage = 'Usuário não encontrado.';
                    break;
                case 'auth/wrong-password':
                    errorMessage = 'Senha incorreta.';
                    break;
                default:
                    errorMessage = 'Erro ao fazer login. Tente novamente.';
            }

            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            await sendPasswordResetEmail(auth, resetEmail);
            alert('Um link de redefinição de senha foi enviado para seu e-mail.');
            setModalOpen(false);
        } catch (error) {
            let errorMessage = 'Ocorreu um erro ao enviar o e-mail de redefinição de senha.';

            switch (error.code) {
                case 'auth/invalid-email':
                    errorMessage = 'Email inválido.';
                    break;
                case 'auth/user-not-found':
                    errorMessage = 'Nenhum usuário encontrado com este e-mail.';
                    break;
                default:
                    errorMessage = 'Erro ao enviar o e-mail. Tente novamente.';
            }

            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoggedIn) {
        return (
            <div className="login-container">
                <div className="login-card">
                    <h2 className="text-2xl font-bold text-center mb-4">Você já está conectado</h2>
                    <p className="text-gray-600 text-center mb-6">
                        Você será redirecionado automaticamente...
                    </p>
                    <button
                        onClick={() => navigate('/status')}
                        className="login-button"
                    >
                        Ir para o Acompanhamento
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="login-container">
            <div className="login-card">
                <div className="logo-container">
                    <img src="/logo192.png" alt="Logo" className="logo-login" />
                </div>

                <h1 className="text-2xl font-bold text-center mb-8" style={{ color: '#ffb400' }}>
                    Bem-vindo
                </h1>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="input-wrapper">
                        <div className="relative">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="input-field pl-10"
                                placeholder=" "
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                            <Mail className="icon h-5 w-5" />
                            <label htmlFor="email" className="input-label">Email</label>
                        </div>
                    </div>

                    <div className="input-wrapper">
                        <div className="relative">
                            <input
                                type="password"
                                name="password"
                                id="password"
                                className="input-field pl-10"
                                placeholder=" "
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                            />
                            <Lock className="icon h-5 w-5" />
                            <label htmlFor="password" className="input-label">Senha</label>
                        </div>
                    </div>

                    <div className="flex items-center mb-4">
                        <input
                            type="checkbox"
                            id="stayLoggedIn"
                            checked={stayLoggedIn}
                            onChange={() => setStayLoggedIn(!stayLoggedIn)}
                            className="mr-2"
                        />
                        <label htmlFor="stayLoggedIn" className="text-sm text-gray-600">Permanecer logado</label>
                    </div>

                    {error && (
                        <div className="error-message">
                            {error}
                        </div>
                    )}

                    <button
                        type="submit"
                        className="login-button"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <span className="flex items-center justify-center">
                                <Loader2 className="spinner mr-2" />
                                Entrando...
                            </span>
                        ) : (
                            'Entrar'
                        )}
                    </button>
                </form>

                <div className="text-center mt-4">
                    <button
                        onClick={() => setModalOpen(true)}
                        className="reset-password-button"
                    >
                        Esqueceu sua senha?
                    </button>
                </div>

                {modalOpen && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h2 className="text-lg font-bold mb-4">Redefinir Senha</h2>
                            <form onSubmit={handleResetPassword}>
                                <input
                                    type="email"
                                    value={resetEmail}
                                    onChange={(e) => setResetEmail(e.target.value)}
                                    className="input-field mb-4"
                                    placeholder="Insira seu e-mail"
                                    required
                                />
                                <button type="submit" className="login-button">
                                    Enviar Link de Redefinição
                                </button>
                            </form>
                            <button className="close-button" onClick={() => setModalOpen(false)}>Fechar</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Login;
