import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './BudgetChat.css';
import { FaPaperPlane } from 'react-icons/fa';
import { FaArrowLeft } from 'react-icons/fa';
import { db } from '../firebase';
import { collection, addDoc } from "firebase/firestore";

const BudgetChat = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [responses, setResponses] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const chatEndRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const introMessage = "Bem-vindo ao nosso formulário de orçamento! Este chat foi projetado para coletar informações para fornecer um orçamento personalizado para o seu site.";

  const questions = [
    "Qual é o seu nome?",
    "Qual o tipo de site que você precisa? (Institucional, E-commerce, etc.)",
    "Quantas páginas você estima para o site?",
    "Você precisa de design personalizado?",
    "Tem alguma funcionalidade específica em mente? (Ex: Blog, integração com redes sociais)",
    "Por favor, informe seu e-mail:",
    "Por favor, informe seu telefone:",
  ];

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const re = /^\(\d{2}\) \d{5}-\d{4}$/;
    return re.test(phone);
  };

  const handleSend = async (value) => {
    if (value || inputValue.trim()) {
      const answer = value || inputValue;

      if ((step === 5 && !validateEmail(answer)) || (step === 6 && !validatePhoneNumber(answer))) {
        setErrorMessage('Por favor, insira um e-mail ou telefone válido.');
        scrollToBottom();
        setTimeout(() => setErrorMessage(''), 3000);
        return;
      }

      setResponses([...responses, { question: questions[step], answer }]);
      setInputValue('');
      setStep(step + 1);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  useEffect(() => {
    if (step === questions.length) {
      handleFinalAction();
      saveDataToFirebase();
    }
  }, [step]);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [responses, isTyping, errorMessage]);

  const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return value;
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (step === 6) {
      setInputValue(formatPhoneNumber(value));
    } else {
      setInputValue(value);
    }
  };

  const handleFinalAction = () => {
    setModalVisible(true);
    setResponses([]);
    setStep(0);
    setInputValue('');
    setCountdown(5);
  };

  const saveDataToFirebase = async () => {
    try {
      await addDoc(collection(db, "responses"), {
        responses: responses.map(response => ({
          question: response.question,
          answer: response.answer
        }))
      });
    } catch (error) {
      console.error("Erro ao salvar os dados no Firebase: ", error);
    }
  };

  useEffect(() => {
    if (modalVisible && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (countdown === 0) {
      setModalVisible(false);
      navigate('/');
    }
  }, [modalVisible, countdown, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="chat-container">
      <div className={`chat-window ${modalVisible ? 'blur' : ''}`}>
        <div className="chat-header">
          {isMobile && (
            <button className="back-button" onClick={() => navigate('/')}>
              <FaArrowLeft />
            </button>
          )}
          <img src="/logo192.png" alt="Logo" className="chat-logo" />
          <h2 className="chat-title">FuturisNex</h2>
        </div>
        <div className="messages">
          {step === 0 && (
            <div className="message bot-question">
              {introMessage}
            </div>
          )}
          {responses.map((response, index) => (
            <div key={index} className="message">
              <div className="bot-question">{response.question}</div>
              <div className="user-answer">{response.answer}</div>
            </div>
          ))}
          {step === 3 && !isTyping && (
            <div className="message bot-question">
              {questions[step]}
              <div className="options">
                <button onClick={() => handleSend('Sim')} className="btn-option">Sim</button>
                <button onClick={() => handleSend('Não')} className="btn-option">Não</button>
              </div>
            </div>
          )}
          {step !== 3 && step < questions.length && !isTyping && (
            <div className="message bot-question">{questions[step]}</div>
          )}
          {isTyping && (
            <div className="message bot-question typing">
              Bot está digitando...
            </div>
          )}
          {errorMessage && (
            <div className="error-message">
              {errorMessage}
            </div>
          )}
          <div ref={chatEndRef} />
        </div>
        <div className="input-container">
          {step < questions.length && step !== 3 && (
            <>
              <input
                type="text"
                placeholder="Digite sua resposta..."
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
              <button onClick={() => handleSend()}>
                <FaPaperPlane />
              </button>
            </>
          )}
        </div>
      </div>
      {modalVisible && (
        <div className="modal">
          <div className="modal-content">
            <h2>Enviado com Sucesso!</h2>
            <p>Em breve, nossa equipe entrará em contato com você para discutir os detalhes.</p>
            <p>Você será redirecionado para a página inicial em <strong>{countdown}</strong> segundos.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BudgetChat;
