import React, { useEffect, useState } from 'react';
import { collection, doc, getDocs, onSnapshot, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Configuração do Firebase
import './AdminPanel.css';

const AdminPanel = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [siteInfo, setSiteInfo] = useState([]);
  const [statusUpdates, setStatusUpdates] = useState([]);
  const [editSite, setEditSite] = useState({});
  const [editStatus, setEditStatus] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      const usuariosSnapshot = await getDocs(collection(db, 'usuarios'));
      setUsuarios(usuariosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchUsers();
  }, []);

  const selectUser = (userId) => {
    setSelectedUser(userId);

    // Carregar as informações do site
    const siteInfoRef = collection(db, `usuarios/${userId}/site_info`);
    const statusUpdatesRef = collection(db, `usuarios/${userId}/status_updates`);

    onSnapshot(siteInfoRef, (snapshot) => {
      const siteData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSiteInfo(siteData);
      // Inicializar as edições com os dados do Firestore
      if (siteData.length === 0) {
        setDoc(doc(db, `usuarios/${userId}/site_info`, 'default'), {
          nome: '',
          tipo: '',
          plataforma: '',
          dominio: '',
          statusAtual: '',
          descricao: ''
        });
      } else {
        setEditSite(siteData.reduce((acc, site) => ({ ...acc, [site.id]: site }), {}));
      }
    });

    onSnapshot(statusUpdatesRef, (snapshot) => {
      const statusData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setStatusUpdates(statusData);
      // Inicializar as edições com os dados do Firestore
      if (statusData.length === 0) {
        setDoc(doc(db, `usuarios/${userId}/status_updates`, 'default'), {
          planejamento: 'pendente',
          design: 'pendente',
          desenvolvimento: 'pendente',
          revisao: 'pendente',
          lancamento: 'pendente'
        });
      } else {
        setEditStatus(statusData.reduce((acc, status) => ({
          ...acc,
          [status.id]: status
        }), {}));
      }
    });
  };

  const handleSiteChange = (siteId, field, value) => {
    setEditSite((prev) => ({
      ...prev,
      [siteId]: { ...prev[siteId], [field]: value },
    }));
  };

  const handleStatusChange = (statusId, step, value) => {
    setEditStatus((prev) => ({
      ...prev,
      [statusId]: {
        ...prev[statusId],
        [step]: value
      }
    }));
  };

  const saveSiteInfo = async (siteId) => {
    const siteDocRef = doc(db, `usuarios/${selectedUser}/site_info`, siteId);
    await updateDoc(siteDocRef, editSite[siteId]);
    alert('Informações do site atualizadas!');
  };

  const saveStatusInfo = async (statusId) => {
    if (!selectedUser) {
      alert('Erro: Usuário não encontrado.');
      return;
    }

    const statusDocRef = doc(db, `usuarios/${selectedUser}/status_updates/${statusId || 'default'}`);

    const updatedStatus = {
      planejamento: editStatus?.[statusId]?.planejamento || 'Pendente',
      design: editStatus?.[statusId]?.design || 'Pendente',
      desenvolvimento: editStatus?.[statusId]?.desenvolvimento || 'Pendente',
      revisao: editStatus?.[statusId]?.revisao || 'Pendente',
      lancamento: editStatus?.[statusId]?.lancamento || 'Pendente',
    };

    try {
      await updateDoc(statusDocRef, updatedStatus);
      alert('Status do projeto atualizado!');
    } catch (error) {
      console.error('Erro ao atualizar os status:', error);
      alert('Erro ao atualizar os status.');
    }
  };

  return (
    <div className="admin-panel">
      <h1>Painel de Administração</h1>

      <div className="admin-content">
        <div className="users-list">
          <h2>Usuários</h2>
          <ul>
            {usuarios.map((user) => (
              <li key={user.id} onClick={() => selectUser(user.id)}>
                {user.nome || `Usuário ${user.id}`}
              </li>
            ))}
          </ul>
        </div>

        {selectedUser && (
          <div className="user-details">
            <h2>Detalhes do Usuário</h2>

            <h3>Informações do Site</h3>
            {siteInfo.map((site) => (
              <div key={site.id} className="site-info">
                <label>Nome:</label>
                <input
                  type="text"
                  value={editSite[site.id]?.nome || ''}
                  onChange={(e) => handleSiteChange(site.id, 'nome', e.target.value)}
                />

                <label>Tipo:</label>
                <input
                  type="text"
                  value={editSite[site.id]?.tipo || ''}
                  onChange={(e) => handleSiteChange(site.id, 'tipo', e.target.value)}
                />

                <label>Plataforma:</label>
                <input
                  type="text"
                  value={editSite[site.id]?.plataforma || ''}
                  onChange={(e) => handleSiteChange(site.id, 'plataforma', e.target.value)}
                />

                <label>Domínio:</label>
                <input
                  type="text"
                  value={editSite[site.id]?.dominio || ''}
                  onChange={(e) => handleSiteChange(site.id, 'dominio', e.target.value)}
                />

                <label>Status Atual:</label>
                <input
                  type="text"
                  value={editSite[site.id]?.statusAtual || ''}
                  onChange={(e) => handleSiteChange(site.id, 'statusAtual', e.target.value)}
                />

                <label>Descrição:</label>
                <input
                  type="text"
                  value={editSite[site.id]?.descricao || ''}
                  onChange={(e) => handleSiteChange(site.id, 'descricao', e.target.value)}
                />

                <button onClick={() => saveSiteInfo(site.id)}>Salvar Informações</button>
              </div>
            ))}

            <h3>Status do Projeto</h3>
            {statusUpdates.map((status) => (
              <div key={status.id} className="status-update">
                {['planejamento', 'design', 'desenvolvimento', 'revisao', 'lancamento'].map((step, index) => (
                  <div key={index}>
                    <label>{step.charAt(0).toUpperCase() + step.slice(1)}:</label>
                    <select
                      value={editStatus[status.id]?.[step] || 'pendente'}
                      onChange={(e) => handleStatusChange(status.id, step, e.target.value)}
                    >
                      <option value="Pendente">Pendente</option>
                      <option value="Em Progresso">Em Progresso</option>
                      <option value="Concluído">Concluído</option>
                    </select>
                  </div>
                ))}
                <button onClick={() => saveStatusInfo(status.id)}>Salvar Status</button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminPanel;
