import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaCheckCircle, FaHourglassHalf, FaTimesCircle, FaArrowRight } from 'react-icons/fa';
import { auth, db } from '../firebase';
import { collection, getDocs, orderBy, query, doc, getDoc } from 'firebase/firestore'; // getDoc adicionado
import './Status.css';

const Status = () => {
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [siteInfo, setSiteInfo] = useState(null); // Estado para informações do site

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const uid = user.uid;

        const fetchStatus = async () => {
          try {
            // Correção aqui para acessar o subdocumento "default"
            const statusRef = doc(db, 'usuarios', uid, 'status_updates', 'default');
            const statusSnapshot = await getDoc(statusRef);

            if (statusSnapshot.exists()) {
              const latestStatusDoc = statusSnapshot.data();

              if (latestStatusDoc.entrega) {
                setDeliveryDate(latestStatusDoc.entrega);
              }

              const updatedSteps = [
                { title: 'Planejamento', status: latestStatusDoc.planejamento || 'Pendente' },
                { title: 'Design', status: latestStatusDoc.design || 'Pendente' },
                { title: 'Desenvolvimento', status: latestStatusDoc.desenvolvimento || 'Pendente' },
                { title: 'Revisão', status: latestStatusDoc.revisao || 'Pendente' },
                { title: 'Lançamento', status: latestStatusDoc.lancamento || 'Pendente' },
              ].map((step) => ({
                ...step,
                ...getStepIconAndColor(step.status),
              }));

              setSteps(updatedSteps);
            } else {
              console.log('Nenhum documento de status encontrado!');
            }

            // Buscar informações do site do cliente
            const siteRef = doc(db, 'usuarios', uid, 'site_info', 'default');
            const siteSnapshot = await getDoc(siteRef);

            if (siteSnapshot.exists()) {
              setSiteInfo(siteSnapshot.data());
            } else {
              console.log('Nenhuma informação do site encontrada.');
            }
          } catch (error) {
            console.error('Erro ao buscar dados do Firestore:', error);
          } finally {
            setLoading(false);
          }
        };

        fetchStatus();
        const interval = setInterval(fetchStatus, 5000);

        return () => clearInterval(interval);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const getStepIconAndColor = (status) => {
    switch (status) {
      case 'Concluído':
        return { icon: <FaCheckCircle />, color: '#d4edda', iconColor: '#28a745' };
      case 'Em Progresso':
        return { icon: <FaHourglassHalf />, color: '#fff3cd', iconColor: '#ffc107' };
      case 'Pendente':
      default:
        return { icon: <FaTimesCircle />, color: '#f8d7da', iconColor: '#dc3545' };
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="status">
      <header className="status-header">
        <h1>Status da Criação do Site</h1>
      </header>
      <div className="steps-container">
        {steps.map((step, index) => (
          <div className="step-container" key={index}>
            <motion.div
              className="step"
              style={{ backgroundColor: step.color }}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
            >
              <div className="icon-status" style={{ color: step.iconColor }}>{step.icon}</div>
              <div className="step-info">
                <h2>{step.title}</h2>
                <p>{step.status}</p>
              </div>
            </motion.div>
            {index < steps.length - 1 && (
              <motion.div className="arrow">
                <FaArrowRight className="arrow-icon" />
              </motion.div>
            )}
          </div>
        ))}
      </div>
      <div className="final-delivery">
        {deliveryDate ? (
          <h2>Entrega Prevista: {deliveryDate}</h2>
        ) : (
          <h2>Entrega Final: Em Breve</h2>
        )}
      </div>
      
      <footer className="status-footer">
        <p>Atualizações em tempo real sobre o processo de criação.</p>
      </footer>

      {siteInfo && (
        <div className="site-info-card">
          <h2>Informações do Site</h2>
          <p><strong>Nome do Site:</strong> {siteInfo.nome}</p>
          <p><strong>Tipo:</strong> {siteInfo.tipo}</p>
          <p><strong>Plataforma:</strong> {siteInfo.plataforma}</p>
          <p><strong>Dominio:</strong> {siteInfo.dominio}</p>
          <p><strong>Status Atual:</strong> {siteInfo.statusAtual}</p>
          <p><strong>Descrição:</strong> {siteInfo.descricao}</p>
        </div>
      )}
    </div>
  );
};

export default Status;
