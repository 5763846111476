import React, { useEffect } from 'react';
import { FaLightbulb, FaChartLine, FaUsers, FaBullseye } from 'react-icons/fa';
import { MdSpeed, MdDesignServices } from 'react-icons/md';
import './About.css';

const About = () => {
  useEffect(() => {
    document.title = "Sobre Nós | Missão, Visão e Valores";
  }, []);

  return (
    <div className="about-container">
      <meta name="description" content="Conheça nossa agência digital, dedicada a transformar ideias em realidades. Veja nossa missão, visão e valores que nos guiam para oferecer soluções inovadoras." />
      <meta name="keywords" content="Agência digital, missão, visão, valores, design, transformação digital, marketing, inovação" />
      <section className="intro-section">
        <div className="intro-content">
          <h1><FaLightbulb className="icon-large" /> Quem Somos</h1>
          <p>
            Somos uma agência inovadora que transforma ideias em realidades digitais de sucesso. Nosso foco é criar soluções digitais que elevam os negócios, com designs personalizados, estratégias de marketing impactantes e tecnologia de ponta.
          </p>
        </div>
      </section>
      <section className="mission-vision-values">
        <h2>Nossa Missão, Visão e Valores</h2>
        <div className="mv-content">
          <div className="mv-item">
            <FaBullseye className="mv-icon" aria-hidden="true" />
            <h3>Missão</h3>
            <p>
              Nossa missão é capacitar empresas a se destacarem no mercado digital, fornecendo soluções inovadoras e orientadas a resultados.
            </p>
          </div>
          <div className="mv-item">
            <FaChartLine className="mv-icon" aria-hidden="true" />
            <h3>Visão</h3>
            <p>
              Ser reconhecida como a principal agência em transformação digital, criando estratégias disruptivas e experiências digitais inesquecíveis.
            </p>
          </div>
          <div className="mv-item">
            <FaUsers className="mv-icon" aria-hidden="true" />
            <h3>Valores</h3>
            <p>
              Transparência, inovação e compromisso com resultados. Nossa equipe está sempre em busca das melhores soluções para cada cliente.
            </p>
          </div>
        </div>
      </section>
      <section className="differentials-section">
        <h2>Por que Escolher Nossa Agência?</h2>
        <div className="differentials">
          <div className="differential-item">
            <MdSpeed className="differential-icon" aria-hidden="true" />
            <h3>Agilidade e Rapidez</h3>
            <p>Entrega rápida e eficiente, sem comprometer a qualidade, garantindo sua presença digital no menor tempo possível.</p>
          </div>
          <div className="differential-item">
            <MdDesignServices className="differential-icon" aria-hidden="true" />
            <h3>Design de Alto Desempenho</h3>
            <p>Criamos interfaces intuitivas e visualmente impactantes, refletindo a identidade única de cada marca.</p>
          </div>
          <div className="differential-item">
            <FaChartLine className="differential-icon" aria-hidden="true" />
            <h3>Resultados Que Importam</h3>
            <p>Focamos em gerar resultados concretos: mais tráfego, maior engajamento e aumento nas conversões.</p>
          </div>
        </div>
      </section>
      <section className="cta-section">
        <h2>Pronto para Levar Seu Negócio para o Próximo Nível?</h2>
        <p>Entre em contato com nossa equipe de especialistas e descubra como podemos transformar digitalmente o seu negócio.</p>
        <a href="/contact" className="btn-secondary">Solicitar uma Consultoria</a>
      </section>
    </div>
  );
};

export default About;
