import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import BudgetChat from './components/BudgetChat';
import About from './components/About';
import FormCadastro from './components/FormCadastro';
import Login from './Login/Login';
import ProtectedRoute from './ProtectedRoute';
import Status from './components/Status';
import ResetPassword from './Login/ResetPassword';
import AdminPanel from './Pages/AdminPanel';

function App() {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const shouldShowHeader = location.pathname !== '/contact' || !isMobile;

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="App">
      {shouldShowHeader && <Header scrolled={scrolled} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<BudgetChat />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/cadastro-cliente" element={<FormCadastro />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/status"
          element={
            <ProtectedRoute>
              <Status />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-painel-status"
          element={
            <ProtectedRoute>
              <AdminPanel />
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;