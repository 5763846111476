// src/components/ModalTermos.js
import React from 'react';
import './ModalTermos.css'; // Adicione estilos para o modal

const ModalTermos = ({ onClose, onAccept }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Termos de Uso</h2>
                <p>Ao se cadastrar, você concorda com os seguintes termos:</p>
                <ol>
                    <li>
                        Você concorda em fornecer informações precisas e completas ao se cadastrar.
                    </li>
                    <li>
                        Você é responsável por manter a confidencialidade de sua senha e conta.
                    </li>
                    <li>
                        Reservamo-nos o direito de suspender ou encerrar sua conta a qualquer momento, 
                        caso as informações fornecidas sejam falsas ou a conta seja usada de forma 
                        inadequada.
                    </li>
                    <li>
                        Não nos responsabilizamos por qualquer perda ou dano resultante de seu uso da 
                        plataforma.
                    </li>
                    <li>
                        Podemos atualizar esses termos a qualquer momento e notificá-lo sobre as 
                        alterações.
                    </li>
                </ol>
                <p>
                    Ao clicar em "Aceito", você confirma que leu e concorda com os termos acima.
                </p>
                <div className="modal-buttons">
                    <button onClick={onAccept}>Aceito</button>
                    <button onClick={onClose}>Cancelar</button>
                </div>
            </div>
        </div>
    );
};

export default ModalTermos;
