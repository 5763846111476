import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FaLightbulb, FaCogs, FaDesktop, FaChartLine, FaQuoteLeft, FaThumbsUp, FaQuestionCircle } from 'react-icons/fa';
import { MdFitScreen, MdDesignServices } from "react-icons/md";
import { RiSeoFill } from "react-icons/ri";
import site from "../assets/3.png";
import loja from "../assets/2.png";
import redesign from "../assets/1.png";
import './Home.css';

const Home = () => {
  useEffect(() => {
    const savedContent = localStorage.getItem('homeContent');

    if (savedContent) {
    } else {
      const currentContent = document.getElementsByClassName('home-container')[0].innerHTML;
      localStorage.setItem('homeContent', currentContent);
    }
  }, []);

  return (
    <div className="home-container" id="home-root">
      <Helmet>
        <title>Transforme Seu Negócio com Sites Profissionais</title>
        <meta name="description" content="Especialistas em criar websites que impressionam e convertem visitantes em clientes. Deixe sua marca brilhar no digital com SEO otimizado!" />
        <meta name="keywords" content="futurisnex, criação de sites, SEO, lojas virtuais, design web, desenvolvimento de sites" />
      </Helmet>
      <section className="hero-section">
        <div className="hero-content">
          <h1><FaLightbulb className="icon-large" /> Transforme Seu Negócio com Sites Profissionais</h1>
          <p>
            Especialistas em criar websites que não apenas impressionam, mas convertem visitantes em clientes. Deixe sua marca brilhar no digital!
          </p>
          <a href="contact" className="btn-primary hero-btn">Solicite um Orçamento</a>
        </div>
      </section>
      <section className="benefits-section">
        <h2><FaThumbsUp className="icon-medium" /> Benefícios de Escolher Nossos Serviços</h2>
        <div className="benefits">
          <div className="benefit">
            <MdDesignServices className="services-icon" />
            <h3>Design Exclusivo</h3>
            <p>Crie uma presença online única com design personalizado que reflete a identidade da sua marca.</p>
          </div>
          <div className="benefit">
            <RiSeoFill className="services-icon" />
            <h3>Otimização de Conversão</h3>
            <p>Maximize seus resultados com estratégias de SEO e UX que convertem visitantes em clientes fiéis.</p>
          </div>
          <div className="benefit">
            <FaChartLine className="services-icon" />
            <h3>Resultados Comprovados</h3>
            <p>Desenvolva um site que não só atrai tráfego, mas também gera vendas e leads qualificados.</p>
          </div>
        </div>
      </section>
      <section className="why-choose-us-section">
        <h2>Por que Escolher Nossos Serviços?</h2>
        <div className="features">
          <div className="feature">
            <MdFitScreen className="services-icon" />
            <h3>Design Responsivo</h3>
            <p>Oferecemos a melhor experiência em qualquer dispositivo.</p>
          </div>
          <div className="feature">
            <FaCogs className="services-icon" />
            <h3>SEO Otimizado</h3>
            <p>Melhore sua posição no Google e aumente sua visibilidade.</p>
          </div>
          <div className="feature">
            <FaDesktop className="services-icon" />
            <h3>Suporte Completo</h3>
            <p>Manutenção contínua para manter seu site sempre atualizado.</p>
          </div>
        </div>
      </section>
      <section className="services-section">
        <h2><FaQuoteLeft className="icon-large" /> Nossos Serviços</h2>
        <div className="services">
          <div className="service-site">
            <img src={site} alt="Criação de Sites" className="service-image" />
            <div className="service-info">
              <h4>Criação de Sites</h4>
              <p>Desenvolvemos sites personalizados que atraem mais clientes e refletem a identidade da sua marca.</p>
            </div>
          </div>
          <div className="service-loja">
            <img src={loja} alt="Lojas Virtuais" className="service-image" />
            <div className="service-info">
              <h4>Lojas Virtuais</h4>
              <p>Criação de lojas online com integração de meios de pagamento, proporcionando uma experiência de compra completa.</p>
            </div>
          </div>
          <div className="service-redesign">
            <img src={redesign} alt="Redesign de Sites" className="service-image" />
            <div className="service-info">
              <h4>Redesign de Sites</h4>
              <p>Modernizamos sites existentes para elevar a sua marca, com um design atualizado e atraente.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="faq-section">
        <h2><FaQuestionCircle className="icon-medium" /> Perguntas Frequentes</h2>
        <div className="faq">
          <div className="faq-item">
            <h3>Quanto tempo leva para criar um site?</h3>
            <p>O tempo de desenvolvimento varia dependendo da complexidade do projeto, mas geralmente leva de 2 a 6 semanas.</p>
          </div>
          <div className="faq-item">
            <h3>Vocês oferecem suporte após o lançamento?</h3>
            <p>Sim, oferecemos suporte contínuo para garantir que seu site funcione perfeitamente e esteja sempre atualizado.</p>
          </div>
          <div className="faq-item">
            <h3>Os sites são otimizados para SEO?</h3>
            <p>Sim, todos os nossos sites são desenvolvidos com práticas de SEO em mente para ajudar a melhorar sua visibilidade nos motores de busca.</p>
          </div>
        </div>
      </section>
      <section className="cta-section">
        <h2>Pronto para Transformar Seu Negócio?</h2>
        <p>Entre em contato hoje e descubra como podemos ajudar a levar sua empresa para o próximo nível.</p>
        <a href="contact" className="btn-secondary">Solicite uma Consultoria</a>
      </section>
    </div>
  );
};

export default Home;
