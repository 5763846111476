// src/components/FormCadastro.js 
import React, { useState } from 'react';
import './FormCadastro.css';
import ModalTermos from './ModalTermos';
import ModalSucesso from './ModalSucesso'; 
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, setDoc } from 'firebase/firestore'; 
import { createUserWithEmailAndPassword } from 'firebase/auth';

const FormCadastro = () => {
    const [formData, setFormData] = useState({
        nome: '',
        sobrenome: '',
        email: '',
        telefone: '',
        dataNascimento: '',
        endereco: '',
        senha: '',
        confirmacaoSenha: '',
        aceite: false,
    });

    const [modalAberto, setModalAberto] = useState(false);
    const [modalSucessoAberto, setModalSucessoAberto] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === 'telefone') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: formatarTelefone(value),
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };

    const formatarTelefone = (telefone) => {
        const apenasNumeros = telefone.replace(/\D/g, '');
        if (apenasNumeros.length <= 10) {
            return apenasNumeros.replace(/(\d{2})(\d{4})(\d+)/, '($1) $2-$3');
        } else {
            return apenasNumeros.replace(/(\d{2})(\d{5})(\d+)/, '($1) $2-$3');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        if (!formData.aceite) {
            setErrorMessage('Você precisa aceitar os termos para se cadastrar.');
            return;
        }

        if (!validarEmail(formData.email)) {
            setErrorMessage('Por favor, insira um e-mail válido.');
            return;
        }

        if (formData.senha !== formData.confirmacaoSenha) {
            setErrorMessage('As senhas não coincidem.');
            return;
        }

        // Criar usuário no Firebase Auth
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.senha);
            const uid = userCredential.user.uid; // Obtendo o UID do usuário

            // Criar o documento do usuário
            await setDoc(doc(db, "usuarios", uid), {
                nome: formData.nome,
                sobrenome: formData.sobrenome,
                email: formData.email,
                telefone: formData.telefone,
                dataNascimento: formData.dataNascimento,
                endereco: formData.endereco,
                // A senha não deve ser armazenada no Firestore por motivos de segurança
            });

            // Adicionar status de atualização na subcoleção do usuário, usando 'default' como documento
            const statusDocRef = doc(db, "usuarios", uid, "status_updates", "default");
            await setDoc(statusDocRef, {
                planejamento: "Pendente",
                design: "Pendente",
                desenvolvimento: "Pendente",
                revisao: "Pendente",
                lancamento: "Pendente",
                createdAt: new Date() // opcional: timestamp da criação
            });

            setSuccessMessage('Cadastro realizado com sucesso!');
            setModalSucessoAberto(true);
            setFormData({ nome: '', sobrenome: '', email: '', telefone: '', dataNascimento: '', endereco: '', senha: '', confirmacaoSenha: '', aceite: false });
            setTimeout(() => {
                setModalSucessoAberto(false);
                navigate('/login');
            }, 2000);
        } catch (error) {
            console.error("Erro ao realizar o cadastro: ", error);
            setErrorMessage('Erro ao realizar o cadastro. Tente novamente.');
        }
    };

    const validarEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    return (
        <div className="form-container">
            <h1>Cadastro de Pessoas</h1>
            <form onSubmit={handleSubmit}>
                {/* Campos do formulário permanecem os mesmos */}
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="nome">Nome:</label>
                        <input type="text" id="nome" name="nome" value={formData.nome} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="sobrenome">Sobrenome:</label>
                        <input type="text" id="sobrenome" name="sobrenome" value={formData.sobrenome} onChange={handleChange} required />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="telefone">Telefone:</label>
                        <input type="tel" id="telefone" name="telefone" value={formData.telefone} onChange={handleChange} required />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="dataNascimento">Data de Nascimento:</label>
                        <input type="date" id="dataNascimento" name="dataNascimento" value={formData.dataNascimento} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="endereco">Endereço:</label>
                        <input type="text" id="endereco" name="endereco" value={formData.endereco} onChange={handleChange} required />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="senha">Senha:</label>
                        <input type="password" id="senha" name="senha" value={formData.senha} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirmacaoSenha">Confirmação de Senha:</label>
                        <input type="password" id="confirmacaoSenha" name="confirmacaoSenha" value={formData.confirmacaoSenha} onChange={handleChange} required />
                    </div>
                </div>
                <div className="form-group">
                    <label>
                        <input className='checkbox' type="checkbox" name="aceite" checked={formData.aceite} onChange={() => setModalAberto(true)} />
                        Aceito os termos
                    </label>
                </div>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <button type="submit" className="submit-button">Cadastrar</button>
            </form>
            {modalAberto && (
                <ModalTermos 
                    onClose={() => setModalAberto(false)} 
                    onAccept={() => {
                        setFormData((prevData) => ({ ...prevData, aceite: true }));
                        setModalAberto(false);
                    }} 
                />
            )}
            {modalSucessoAberto && (
                <ModalSucesso 
                    mensagem={successMessage} 
                    onClose={() => setModalSucessoAberto(false)} 
                />
            )}
        </div>
    );
};

export default FormCadastro;
