import React, { useState, useEffect } from 'react';
import logo from '../assets/logo_header.png'; // Logo padrão
import logoMobile from '../assets/logo.png'; // Logo para dispositivos móveis
import whatsappLogo from '../assets/whatsappLogo.png';
import './Header.css';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Defina o valor que deseja para alterar o fundo
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="container">
        <div className="logo">
          <img src={logo} alt="Company Logo" className="desktop-logo" />
          <img src={logoMobile} alt="Mobile Logo" className="mobile-logo" />
        </div>
        <ul className="nav-links">
          <li><a href="/">Inicio</a></li>
          <li><a href="/status">Status</a></li>
          <li><a href="contact">Contato</a></li>
          <li><a href="about">Sobre</a></li>
        </ul>
        <div className="cta">
          <a href="https://wa.me/5569993933337" className="btn-primary" target="_blank" rel="noopener noreferrer">
            WhatsApp
            <img src={whatsappLogo} alt="WhatsApp" className="whatsapp-logo" />
          </a>
        </div>
      </div>
      <a href="https://wa.me/5569993933337" className="whatsapp-button" target="_blank" rel="noopener noreferrer">
        <img src={whatsappLogo} alt="WhatsApp" className="whatsapp-mobile-logo" />
      </a>
    </header>
  );
};

export default Header;
