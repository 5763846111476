import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const ENCRYPTION = process.env.REACT_APP_ENCRYPTION;

const ProtectedRoute = ({ children }) => {
    const cookieAuth = Cookies.get('authenticated');
    const localAuth = localStorage.getItem('authenticated');
    const decryptedCookieAuth = cookieAuth ? CryptoJS.AES.decrypt(cookieAuth, ENCRYPTION).toString(CryptoJS.enc.Utf8) : null;
    const decryptedLocalAuth = localAuth ? CryptoJS.AES.decrypt(localAuth, ENCRYPTION).toString(CryptoJS.enc.Utf8) : null;
    const isAuthenticated = decryptedCookieAuth === 'true' || decryptedLocalAuth === 'true';
    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
