import React from 'react';
import './ModalSucesso.css';

const ModalSucesso = ({ mensagem, onClose }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Sucesso!</h2>
                <p>Redirecionando você para página de acompanhamento.</p>
                <p>{mensagem}</p>
            </div>
        </div>
    );
};

export default ModalSucesso;
