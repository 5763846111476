import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../firebase';
import './Login.css';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [oobCode, setOobCode] = useState(new URLSearchParams(window.location.search).get('oobCode'));

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            await confirmPasswordReset(auth, oobCode, newPassword);
            alert('Senha redefinida com sucesso!');
            navigate('/login');
        } catch (error) {
            let errorMessage = 'Ocorreu um erro ao redefinir a senha.';

            switch (error.code) {
                case 'auth/invalid-action-code':
                    errorMessage = 'Código de ação inválido.';
                    break;
                case 'auth/expired-action-code':
                    errorMessage = 'O código de ação expirou.';
                    break;
                case 'auth/weak-password':
                    errorMessage = 'A senha deve ter pelo menos 6 caracteres.';
                    break;
                default:
                    errorMessage = 'Erro ao redefinir a senha. Tente novamente.';
            }

            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <h1 className="text-2xl font-bold text-center mb-8" style={{ color: '#ffb400' }}>
                    Redefinir Senha
                </h1>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="input-wrapper">
                        <div className="relative">
                            <input
                                type="password"
                                name="newPassword"
                                className="input-field"
                                placeholder=" "
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                            <label htmlFor="newPassword" className="input-label">Nova Senha</label>
                        </div>
                    </div>

                    {error && (
                        <div className="error-message">
                            {error}
                        </div>
                    )}

                    <button
                        type="submit"
                        className="login-button"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Redefinindo...' : 'Redefinir Senha'}
                    </button>
                </form>

                <div className="text-center mt-4">
                    <button
                        onClick={() => navigate('/login')}
                        className="reset-password-button"
                    >
                        Voltar para o Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
